.button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 0;
  border-left: 1px solid black;
  :hover{
    background-color: gray;
  }
  :active{
    background-color: #DF9930;
  }
}
.button_img{

}