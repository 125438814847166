@import "src/shared/styles/index";


.location_logo {
    width: 14.18px;
    height: 19.89px;
    margin-right: 3px;
}

.sweet_info{
    display: flex;
    flex-direction: column;
}

.cost_weight{
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    font-family: Roboto sans-serif;
}

.location {
    display: flex;
    margin-top: 8px;
    font-family: Bellota Text, sans-serif;
}

.stars {
    margin-top: 8px;
}

.order_button {
    margin-top: 18px;
}

.title {
    @include H1-Medium
}

.weight{
    margin-left: 5px;
}
