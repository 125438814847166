.contacts_modal{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts_modal_content{
  display: flex;
  width: 30vw;
  padding: 36px 51px 42px 51px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 20px;
  border-radius: 5px;
  background: #FFF;
}
.phone_number_div{
  width: 300px;
  height: 1px;
  background: #BDBDBD;
}
.user_data{
  color: #DF9930;
}
