.img_style{
    width: 870px; 
    height: 500px; 
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.no_image {
    width: 870px; 
    height: 500px; 
    object-fit: contain;
}