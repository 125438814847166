@import "src/shared/styles/index";


.title{
    // @include H2;
    margin-bottom: 10px;
}

.description{
    display: flex;
    flex-direction: column;
    // @include H3-Regular;
}