.card{
    //width: auto;
    height: 100%;

    //width: 360px;
    //height: 430px;

    border: 1px solid #BDBDBD;
    border-radius: 5px;
    padding-bottom: 5px;
}
.image_div{
    width: 360px;
    height: 236px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;

}
.sweet_info_div{
    padding: 10px 10px 0 10px;
    width: 340px;
    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
.cost_weight_rate{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.cost_weight_container{
    display: flex;
}
