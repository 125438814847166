@import "src/shared/styles/index";

.profile_wrapper{
    display: flex;
    //flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    align-content: center;
    //align-items: center;
    margin-top: 30px;
    margin-left: 50px;
    margin-bottom: 30px;

}
.page_content{
    display: flex;
    flex-wrap: wrap;
}
.filters{
    width: 20%;
    height: 1380px;
    border: 1px solid #BDBDBD;
}
.filters_div{
    margin-top: 18px;
}
.content{
    display: block;
    width: 80%;
    height: 1380px;
    margin-left: 30px;
}
.user_info{
    display: flex;
    height: 420px;
}
.user_avatar_div{
    width: 420px;
    height: 420px;
    border: 1px solid black;
}
.user_avatar{
    width: 420px;
    height: 420px;
}
.user_info_div{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    justify-content: start;
    align-items: start;
    row-gap: 10px;
    //@include H3-Medium;
}
.change_image_button_container{
    display: flex;
    flex-direction: column;
    width: 250px;
    row-gap: 15px;
}
.edit_info_input{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    background: #F5F5F5;
}
.file_input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 20px;
    cursor: pointer;
}

.custom_file_label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #eaeaea;
    color: #333;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.custom_file_label:hover {
    background-color: #ccc;
}
.products{
    grid-row: 2;
    //width: 80%;
    height: auto;
    margin-top: 27px;
    border-bottom: 1px solid #BDBDBD;
    padding-bottom: 10px;
}
.products_list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: start;
    row-gap: 20px;
    column-gap: 8px;
}
.comments{
    grid-row: 3;
    margin-top: 10px;
    border: 1px solid #BDBDBD;
    border-radius: 5px;
    margin-right: 50px;
}

h1{
    @include H1-Medium;
}
h2{
    @include H2;
}
h3{
    @include H3-Regular
}