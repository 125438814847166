.wrapper{
  display: flex;
  border-bottom: 1px solid #DF9930;
  height: 80px;
}

.logo{
  margin-left: 50px;
  width: 270px;
  display: flex;
  background-color: #DF9930;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  .img{
  }
}

.navbar_forms_container {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.search_container{
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.search_bar_container{
  display: flex;
  margin-right: 30px;
  overflow: clip;
  border: 1px solid black;
  border-radius: 5px;
}

.button_container{
  //width: 430px;
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}
.basket_btn{
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #DF9930;
  background: #FFF;
}
.basket_icon{
  width: 25px;
  height: 25px;
  color: #DF9930;
}
