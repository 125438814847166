.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000 !important;
    z-index: 100001 !important;
}
.modal_content{
    height: auto;
    width: 54vmin;
    padding: 30px;
    border-radius: 5px;
    background-color: white;
    border:1px solid #DF9930;
}
.modal_services_div{
    width: auto;
    height: 119px;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 29px;
}
.modal_services{
    display: grid;
    justify-content: start;
    align-items: center;
    row-gap: 7px;
    column-gap: 20px;
}
.modal_google{
    grid-column: 1;
    grid-row: 2;
    width: 50px;
    height: 50px;
}
.modal_yandex{
    grid-column: 2;
    grid-row: 2;
    border: 2px solid rgba(224, 48, 48, 1);
    border-radius: 5px;
    background-color: rgba(224, 48, 48, 1);
    color: white;
    width: 50px;
    height: 50px;
}
.modal_vk_div{
    grid-column: 3;
    grid-row: 2;
    border: 3px solid rgba(77, 118, 161, 1);
    background-color: rgba(77, 118, 161, 1);
    border-radius: 50px;
    width: 50px;
    height: 50px;
}
.modal_vk{
    border: none;
    color: white;
    width: 50px;
    height: 50px;
}
.modal_input_fields{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    //margin-bottom: 30px;
}

.modal_input{
    width: 53vmin;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    background: #F5F5F5;
    padding-left: 10px;
}
.modal_input:focus{
    outline: none;
}
.modal_input::placeholder {
    transition: 0.5s;
    color: #BDBDBD;
}
.modal_input:focus::placeholder {
    font-size: 10px;
    transform: translateY(-16px);
}
.modal_footer{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
}