.profile_wrapper{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-top: 30px;
    margin-left: 50px;

}
.filters{
    width: 20%;
    height: 100vh;
    border: 1px solid #BDBDBD;
}
.filters_div{
    margin-top: 18px;
}
.content{
    display: block;
    width: 80%;
    margin-left: 30px;
}
.user_info{
    display: flex;
    height: 420px;
}
.user_avatar_div{
    width: 420px;
    height: 420px;
    border: 1px solid black;
}
.user_avatar{
    width: 420px;
    height: 420px;
}
.user_info_div{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    justify-content: start;
    align-items: start;
    row-gap: 10px;
}
.edit_info_input{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    background: #F5F5F5;
}
