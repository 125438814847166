@import "normalize";

html{
  font-family: Bellota Text,sans-serif;
}
h1{
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
h2{
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
h3{
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
filter{
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
button{
  font-family: Bellota Text, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@mixin H1-Medium{
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@mixin H2{
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@mixin H2-Regular{
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@mixin H3-Light{
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@mixin H3-Regular{
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@mixin H3-Medium{
  font-size: 24px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@mixin H5{
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
@mixin Filter-Regular{
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@mixin Filter-Light {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@mixin Button{
  font-family: Bellota Text, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@mixin Footer{
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@mixin Location{
  font-family: Bellota Text, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

textarea{
  padding: 10px 0 0 10px;
  font-size: 18px;
}
textarea:focus{
  outline: none;
}

input{
  font-size: 20px;
}

input[type="file"] {
  color: white !important;
  width: 144px;
  height: 60px;
  background-color: #DF9930 !important;
  border:solid #DF9930 1px;
  border-radius: 5px;
}

input[type="file"]:hover {
  background-color: white;
  color: #DF9930;
}