.catalog_wrapper{
    display: flex;
    margin-top: 30px;
}
.filters{
    width: 20%;
    height: 1380px;
    border: 1px solid #BDBDBD;
    margin-left: 50px;
}
.filters_div{
    margin-top: 18px;
}
.catalog{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: start;
    width: 80%;
    row-gap: 10px;
    column-gap: 8px;
    margin-left: 30px;
}
.catalog_card{
    height: auto;
    a {height: 100%;}
    margin-bottom: 10px;
}
.sweet_table{
    display: flex;
    align-items: center;
    justify-content: center;
}

.table{
    width: 1320px;
    height: 2520px;
    display: flex;
    flex-direction: column;
}

.items{
    width: 720px;
    height: 1920px;
    border: 1px solid black;
}

.page_buttons{
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
}
