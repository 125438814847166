.user_page_wrapper{
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  margin-top: 30px;
}
.user_page_filters{
  width: 20%;
  height: 1380px;
  border: 1px solid #BDBDBD;
  margin-left: 50px;
}
.user_page_filters_div{
  margin-top: 18px;
}
.user_page_content{
  display: block;
  width: 80%;
  height: 1380px;
  margin-left: 30px;
}
.user_page_info{
  display: flex;
  height: 420px;
}
.user_page_avatar_div{
  width: 420px;
  height: 420px;
  border: 1px solid black;
}
.user_page_avatar{
  width: 420px;
  height: 420px;
}
.user_page_info_div{
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  justify-content: start;
  align-items: start;
  row-gap: 10px;
}
.user_page_products{
  grid-row: 2;
  width: 80%;
  height: 687px;
  margin-top: 27px;
  border-bottom: 1px solid #BDBDBD;
}
.user_page_comments{
  grid-row: 3;
  margin-top: 10px;
  border: 1px solid #BDBDBD;
  border-radius: 5px;
  margin-right: 50px;
}