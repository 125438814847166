.sweet_wrapper{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sweet{
    display: flex;
}

.pics{
    display: flex;
    flex-direction: column;
}

.sweet_pics{
    width: 870px;
    height: 500px;
    border: 1px solid black;
    background-color: #cccccc;
    position: relative;
}

.information{
    width: 420px;
    height: 803px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 15px;
}

.sweet_info{
    width: 420px;
    height: 312px;
}

.hr{
    margin-top: -40px;
    margin-bottom: 30px;
    color: #BDBDBD;
}

.user_info{
    width: 420px;
    height: 274px;
}

.description{
    width: 870px;
    height: 475px;
    margin-top: 125px;
}

.filters{
    width: 20%;
    height: 1100px;
    border: 1px solid #BDBDBD;
    margin-right: 15px;
}

.filters_div{
    margin-top: 0px;
}
