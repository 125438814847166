.dropdown_div{
  display: flex;
  align-items: center;
  row-gap: 10px;
}

.dropdown{
  width: 20vmin;
  //height: 48px;
  border: none;
  color: #DF9930;
  appearance: none;
  font-family: Bellota Text, serif;
  font-size: 20px;
  padding: 10px;
  //row-gap: 10px;

}
.dropdown_item{
  //padding: 10px;
}
.dropdown:focus{
  border: none;
  outline: none;
}
