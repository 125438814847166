.sweet_wrapper{
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 50px;
}
.filters{
    width: 20%;
    height: 1380px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
}
.filters_div{
    margin-top: 18px;
}
.sweet_info{
    width: 60%;
    padding: 0 30px 0 30px;
}
.sweet_user{
    width: 20%;
    margin-right: 100px;
}
.sweet{
    display: flex;
    flex-direction: column;
    padding: 30px 0 0 0;
}

.pics{
    padding: 10px 0 30px 0;
    display: flex;
    flex-direction: column;
}

.sweet_pics{
    width: auto;
    height: 180px;
    border: 1px dashed #BDBDBD;
    margin-top: 15px;
}

.sweet_pics_small{
    width: 870px;
    height: 100px;
    border: 1px solid black;
}

.information{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 25px;
    padding: 0 0 25px 0;
}
.input_field{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    background: #FFF;
}
.text_area{
    width: 420px !important;
    height: 60px !important;

}

.file_input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.file_input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.custom_file_label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #eaeaea;
    color: #333;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.custom_file_label:hover {
    background-color: #ccc;
}