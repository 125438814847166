.comments_info_div{
  margin-left: 24px;
  margin-top: 23px;
  margin-bottom: 30px;
}
.comments_user_info_div{
  display: flex;
  margin-bottom: 14px;
}
.comments_avatar_block{
  margin-right: 23px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.comments_image{
  width: 50px;
  height: 50px;
}
.comments_user_info{
  display: grid;
  row-gap: 7px;
  column-gap: 20px;
}
.comments_original_name{
  grid-column: 1;
}
.comments_user_name{
  grid-column: 2;
  color: #6A6A6A;
}
.comments_registration_date{
  grid-column: 3;
  color: #BDBDBD;
}
.comments_rating{
  grid-row: 2;
}
.comments_text{
  width: 100%;
}