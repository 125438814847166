.modal{
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000 !important;
    z-index: 100001 !important;
}
.modal_content{
    border-radius: 5px;
    background-color: white;
    border: 1px solid #DF9930;
}
.modal_input_fields{
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.date_div{
    display: flex;
    column-gap: 30px;
    color: #DF9930;
}
.date_input_field{
    margin-top: 20px;
    margin-left: 10px;
    color: #DF9930;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    font-size: 20px;
}
.time_div{
    display: flex;
    column-gap: 30px;
    color: #DF9930;
}
.date_time_input{
    width: 120px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    color: #DF9930;
    font-size: 20px;
    padding-left: 10px;
}
input[type="date"] {
    position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}
.date_field{
    display: flex;
    column-gap: 30px;
    color: #DF9930;
}
.input_field{
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    color: #DF9930;
    font-size: 20px;
    padding-left: 10px;
}
input[type="time"] {
    position: relative;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}
.time_field{
    display: flex;
    column-gap: 30px;
    color: #DF9930;
}
.amount_field{
    display: flex;
    column-gap: 30px;
    color: #DF9930;
}
.footer{
    width: 885px;
    height: 250px;
    border-top: 1px solid #BDBDBD;
    background: #F5F5F5;
}
.comment_block{
    width: 885px;
    height: 250px;
    border-top: 1px solid #BDBDBD;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}
.additional_info_field{
    margin-top: 20px;
    width: 828px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    background: #FFF;
}