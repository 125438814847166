.page_wrapper{
  display: flex;
  margin-top: 30px;
}
.filters{
  width: 20%;
  height: 1380px;
  border: 1px solid #BDBDBD;
  margin-left: 50px;
}
.filters_div{
  margin-top: 18px;
}
.info{
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 0 30px 0 30px;
  row-gap: 15px;
}
.text_area_style{
  color: #6A6A6A;
  border-color: #6A6A6A;
  border-radius: 5px;
}
.buttons{
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}
.customer{
  width: 20%;
  margin-right: 50px;
}