.input{
  height: 38px;
  padding: 0;
  border: 0;
  width: 400px;
  :active{
    border: 0;
  }
}
.input:focus{
  border: none;
  outline: none;
}