@import "src/shared/styles/index";


.title {
    @include H2;
}

.user_info{
    display: flex;
    flex-direction: column;
}

.user_stats{
    display: flex;
    margin-top: 18px;
}

.user_avatar{
    height: 90px;
    width: 90px;
    object-fit: cover;
}

.user_avatar_no_avatar{
    height: 90px;
    width: 90px;
    object-fit: cover;
}

.user_avatar_div{
    height: 90px;
    width: 90px;
    border: 1px solid black;
}

.user_stats_text {
    margin-left: 8px;
    margin-top: -5px
}

.username {
    margin-top: 7px;
    @include H3-Regular
}

.company_name {
    margin-top: 7px;
    color: #6A6A6A;
    @include H3-Regular;
}

.user_stars {
    margin-top: 7px;
}

.registration_date {
    margin-top: 1px;
    margin-bottom: 15px;
    color: #6A6A6A;
    // @include Filter-Regular;
}

.communicate_button{
    margin-top: 15px;
}
