.card{
    width: auto;
    height: 350px;
    border: 1px solid #BDBDBD;
    display: flex;
    margin: 30px 50px 0 50px;
    //flex-direction: column;
}
.image_container{
    width: 460px;
    height: 265px;
    object-fit: cover;
}
.info{
    display: grid;
    grid-auto-rows: 50px;
    //grid-auto-columns: 200px;
    column-gap: 10px;
    padding: 15px 0 0 30px;
}
.input_container{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    grid-row: 2;
}
.inputs{
    width: auto;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    color: #DF9930;
    font-size: 20px;
    padding-left: 10px;
}
.customer{
    grid-row: 2;
    grid-column: 2;
}