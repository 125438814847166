@import "src/shared/styles/index";

.dropdown {
    display: inline-block;
    position: relative;
    justify-content: center;
    //width: 50px;
    margin-left: 20px;
}

.menu_btn{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #DF9930;
    background: #FFF;
}
.profile_page_button{
    color: rgba(106, 106, 106, 1);
    background: #FFFFFF;
    border: #FFFFFF;
    font-family: Roboto, serif;
    font-size: 16px;
    width: 200px;
    text-align: left;
}
.exit_button{
    background: #FFFFFF;
    border: #FFFFFF;
    font-family: Roboto, serif;
    font-size: 16px;
    width: 200px;
    text-align: left;
    @include Filter-Regular;

}
.user_icon{
    color: #DF9930;
    width:25px;
    height: 25px;
    background: white;
}
.dropdown_content {
    width: auto;
    height: auto;
    display: none;
    position: absolute;
    right: 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
}

.dropdown:hover .dropdown_content {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
    justify-items: center;
}

a {
    display: block;
    color: #000000;
    text-decoration: none;
}

.dropdown_content a:hover {
    color: #FFFFFF;
    background-color: #00A4BD;
}

.item{
    width: auto;
    height: 50px;
    @include Filter-Regular;
}